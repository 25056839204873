import Record from "../Components/Record/Record";
import Navbar from '../Components/Navbar/Navbar'

const RecordTrack = () => {
  return (
    <>
      <Navbar>
        <Record />
      </Navbar>
    </>
  )
}

export default RecordTrack;