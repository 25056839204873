import SetupGuide from '../Components/SetupGuide/SetupGuide'

function Setup() {
  return (
    <>
      <SetupGuide />
    </>
  )
}

export default Setup;