import { Track, Script, User } from "./Interfaces";



// This file is all stuff that isn't changed often, and are referred to by different parts of the software.

// The highest and lowest possible values of the headset's data that we will actually use and parse into musical data.
// Anything under the maximum and above the minimum will be sorted into respective notes, but anything above the maximum
// or below the minimum will be treated as rests. 
export const MAX_AMPLITUDE = 0.001;
export const MIN_AMPLITUDE = -.001;

// The distance between the ceiling amplitude and the floor amplitude.
export const MIN_MAX_AMPLITUDE_DIFFERENCE = MAX_AMPLITUDE - MIN_AMPLITUDE;

/* The amplitude offset is important since most of the EEG input is coming in as negative values, in order to counter this
    when we divide by 10^8 to get a value in our range of amplitudes we add .001 to ensure we are getting within the range
    required to play audio. */
export const AMPLITUDE_OFFSET = 0.001;

// We only support these, now that we've 
export const BPMS = [
    100, 
    120, 
    140, 
    160,
]

export const KEYS = [
    "C",
    "C#",
    "D",
    "D#",
    "E",
    "F",
    "F#",
    "G",
    "G#",
    "A",
    "A#",
    "B"
];

export const KEY_SIGNATURES_MAJOR =
    [
        ["C", "D", "E", "F", "G", "A", "B"], // 0 done
        ["C#", "D#", "F", "F#", "G#", "A#", "C"], // 1 done
        ["D", "E", "F#", "G", "A", "B", "C#"], // 2 done
        ["D#", "F", "G", "G#", "A#", "C", "D"], // 3 done
        ["E", "F#", "G#", "A", "B", "C#", "D#"], // 4 done
        ["F", "G", "A", "A#", "C", "D", "E"], // 6 done
        ["F#", "G#", "A#", "B", "C#", "D#", "E#"], // 7 done
        ["G", "A", "B", "C", "D", "E", "F#"], // 8 done
        ["G#", "A#", "C", "C#", "D#", "F", "G"], // 9 done
        ["A", "B", "C#", "D", "E", "F#", "G#"], // 10 done
        ["A#", "C", "D", "D#", "F", "G", "A"], // 11 done 
        ["B", "C#", "D#", "E", "F#", "G#", "A#"] // 12 done
    ];
export const KEY_SIGNATURES_MINOR =
    [
        ["C", "D", "D#", "F", "G", "G#", "A#"], // 0 done
        ["C#", "D#", "E", "F#", "G#", "A", "B"], // 1 done
        ["D", "E", "F", "G", "A", "A#", "C"], // 2 done 
        ["D#", "F", "F#", "G#", "A#", "B", "C#"], // 3 done 
        ["E", "F#", "G", "A", "B", "C", "D"], // 4 done 
        ["F", "G", "G#", "A#", "C", "C#", "D#"], // 6 done
        ["F#", "G#", "A", "B", "C#", "D", "E"], // 7 done
        ["G", "A", "A#", "C", "D", "D#", "F"], // 8 done
        ["G#", "A#", "B", "C#", "D#", "E", "F#"], // 9 done
        ["A", "B", "C", "D", "E", "F", "G"], // 10 done
        ["A#", "C", "C#", "D#", "F", "F#", "G#"], // 11 done
        ["B", "C#", "D", "E", "F#", "G", "A"] // 12 done
    ];
export const KEY_SIGNATURES = [KEY_SIGNATURES_MAJOR, KEY_SIGNATURES_MINOR]

export var sampleRate = 44100;

//Default volume value
export const DEFAULT_VOLUME = 0.1;


var defaultTrackImage =  "bbmascot-wBackground-eyes_open_smiling.png"

export const emptyTrack:Track = {
    "id": "",           // auto generated by db
    "title": "",
    "bpm": 120,
    "key": "",
    "scale": "",
    'instruments': null,
    "noteTypes": null,
    "likeCount": 0,
    "midi": "",
    "thumbnail": defaultTrackImage,
    "user": null,
    "userID": "",
    "playlistTracks": null,
    "public": false,
    "like": null
}

export const emptyScript:Script = {
    "id": "",           
    "title": "",
    "user": null,
    "userID": "",
    "public": false,
    "cards": [],
    "likeCount": 0,
}

export const emptyUser:User = {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    username: '',
    bio: '',
    profilePicture: '',
}

const delta = [0.5, 4];
const theta = [4, 8];
const alpha = [8, 13];
const beta = [13, 30];
const gamma = [30, 100];

export const eegFreqs = { delta, theta, alpha, beta, gamma }

// Length 27
export const alphaPatterns = [
    [1, 2],
    [2, 2],
    [2, 2],
    [2, 2],
    [3, 2],
    [4, 2],
    [-1, 2],
    [-1, 2],
    [-1, 2],
    [-2, 2],
    [-3, 2],
    [7, 2],
    [6, 2],
    [5, 2],
    [1, 3],
    [2, 3],
    [3, 3],
    [4, 3],
    [-1, 3],
    [-2, 3],
    [-3, 3],
    [7, 3],
    [6, 3],
    [5, 3],
    [0, 2],
    [0, 2],
    [0, 3],
];

// Length 43
export const betaPatterns = [
    [1, 2, 1],
    [3, 4, 1],
    [2, 3, 1],
    [1, 3, 1],
    [1, -2, 1],
    [1, 4, 1],
    [1, 5, 1],
    [2, 4, 1],
    [2, 3, 1],
    [2, 3, 1],
    [2, -1, 1],
    [5, 4, 1],
    [4, 5, 1],
    [4, 3, 1],
    [1, -1, 1],
    [-1, -2, 1],
    [-1, -2, 1],
    [-1, -2, 1],
    [-1, 2, 1],
    [6, 5, 1],
    [-2, -3, 1],
    [1, 1],
    [2, 1],
    [3, 1],
    [4, 1],
    [-1, 1],
    [-2, 1],
    [-3, 1],
    [7, 1],
    [6, 1],
    [5, 1],
    [0, 2],
    [0, 2],
    [0, 1, 1],
    [0, 2, 1],
    [0, 3, 1],
    [0, 4, 1],
    [0, 5, 1],
    [0, 6, 1],
    [0, -1, 1],
    [0, -2, 1],
    [0, -3, 1],
    [0, -4, 1],
];

// Length 31
export const gammaPatterns = [
    [1, 2, 3, 4, 0],
    [2, 3, 4, 5, 0],
    [1, 2, 3, 2, 0],
    [5, 4, 3, 2, 0],
    [-1, -2, -3, -4, 0],
    [6, 5, 4, 6, 0],
    [5, 4, 3, 5, 0],
    [-2, -3, -4, -2, 0],
    [1, 2, 3, 1, 0],
    [1, 3, 2, 3, 0],
    [3, 2, 1, 3, 0],
    [2, 1, -1, 2, 0],
    [3, 2, 1, -1, 0],
    [-1, 1, 2, 3, 0],
    [1, -1, -2, -1, 0],
    [1, -1, -2, -3, 0],
    [1, 2, 1, -1, 0],
    [1, 0, 2, 0, 0],
    [2, 0, 3, 0, 0],
    [2, 3, 4, 2, 0],
    [1, 0, -1, 0, 0],
    [-1, 0, -2, 0, 0],
    [2, 3, 0, 0, 0],
    [2, 3, 0, 0, 0],
    [3, 4, 0, 0, 0],
    [1, 2, 0, 0, 0],
    [-1, -2, 0, 0, 0],
    [-1, -2, 0, 0, 0],
    [3, 2, 0, 0, 0],
    [2, 1, 0, 0, 0],
    [-1, 1, 0, 0, 0],
];

// Length 29
export const thetaPatterns = [
    [1, 3, 1],
    [3, 5, 1],
    [1, 3, 1],
    [3, 5, 1],
    [7, 5, 1],
    [1, -6, 1],
    [-1, -3, 1],
    [-2, -4, 1],
    [1, -3, 1],
    [-2, -4, 1],
    [1, 4, 1],
    [4, 7, 1],
    [1, 5, 1],
    [5, 9, 1],
    [2, 4, 1],
    [1, 6, 1],
    [1, -3, 1],
    [4, 5, 1],
    [-2, -3, 1],
    [1, 3, 1],
    [3, 5, 1],
    [1, 3, 1],
    [3, 5, 1],
    [7, 5, 1],
    [1, -6, 1],
    [-1, -3, 1],
    [-2, -4, 1],
    [1, -3, 1],
    [-2, -4, 1],
    [1, 4, 1],
    [4, 7, 1],
    [1, 5, 1],
    [5, 9, 1],
    [2, 4, 1],
    [1, 6, 1],
    [1, -3, 1],
    [4, 5, 1],
    [-2, -3, 1],
    [1, 4],
    [2, 4],
    [3, 4],
    [4, 4],
    [-1, 4],
    [-2, 4],
    [-3, 4],
    [7, 4],
    [6, 4],
    [5, 4],
    [0, 4],
];
