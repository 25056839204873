import VerifyPage from '../Components/Verify/Verify'

const Verify = () => {
  return (
    <>
      <VerifyPage />
    </>
  )
}

export default Verify;