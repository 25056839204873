import LoginPage from '../Components/Login/Login'
import Navbar from '../Components/Navbar/Navbar';

const Login = () => {
  return (
    <>
      <Navbar>
        <LoginPage />
      </Navbar>      
    </>
  )
  }
  
  export default Login;