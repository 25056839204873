import Navbar from '../Components/Navbar/Navbar';
import SignUpPage from '../Components/SignUp/SignUp'

const SignUp = () => {
  return (
    <>
      <Navbar>
        <SignUpPage />
      </Navbar>      
    </>
  )
  }
  
  export default SignUp;